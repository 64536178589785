<template>
  <div>
    <v-container>
      <v-row class="mb-8">
        <v-col>
          <v-card-title>
            <h2>Ecommerce Products</h2>
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon small left>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in menuItems"
                  :key="index"
                  @click="item.action"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="tabs">
            <v-tab v-for="(item, i) in tabItems" :key="i">
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item v-for="(item, i) in tabItems" :key="i">
              <component :is="item.component" :ref="item.component"></component>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <CategoryModal
      :dialog="categoryDialog"
      @closeDialog="categoryDialog = false"
      @categorySubmitted="tabs = 1"
      ref="Category"
    />
    <ProductModal
      :dialog="productDialog"
      @closeDialog="productDialog = false"
      @productSubmitted="tabs = 0"
      ref="Product"
    />
  </div>
</template>
<script>
import store from "../store";
import { mapActions } from "vuex";
export default {
  name: "EcommerceProductDashboard",
  components: {
    ProductTable: () => import("../components/Tables/ProductTable.vue"),
    CategoryTable: () => import("../components/Tables/CategoryTable.vue"),
    CategoryModal: () => import("../components/Modals/CategoryModal.vue"),
    ProductModal: () => import("../components/Modals/ProductModal.vue"),
  },
  data() {
    return {
      tabs: 0,
      tabItems: [
        { name: "Products", component: "ProductTable" },
        { name: "Categories", component: "CategoryTable" },
      ],
      productDialog: false,
      categoryDialog: false,
      menuItems: [
        {
          name: "Product",
          action: () => {
            this.productDialog = true;
            this.$refs.Product.getProducts();
          },
        },
        {
          name: "Category",
          action: () => {
            this.categoryDialog = true;
            this.$refs.Category.getCategories();
          },
        },
      ],
    };
  },
  created() {
    this.setCategories();
    this.setProducts();
  },
  store,
  methods: {
    ...mapActions(["setCategories", "setProducts"]),
  },
};
</script>