<template>
  <div>
    <v-container fluid>
      <!--Header-->
      <v-row dense>
        <v-col lg="10" offset-lg="1">
          <v-card-title>
            <!--Back button-->
            <v-btn light color="#ffffff" elevation="0" to="/ecommerce/products">
              <v-icon left large>mdi-chevron-left</v-icon>
            </v-btn>
            <!--Save-->
            <h3>{{ name }}</h3>
          </v-card-title>
          <!--Form-->
          <v-card class="pa-3">
            <v-form @submit.prevent="updateProduct" ref="form" v-model="valid">
              <v-container>
                <!--Description-->
                <v-row>
                  <v-col cols="12">
                    <h4>Description</h4>
                  </v-col>
                </v-row>
                <!---->
                <v-row>
                  <v-col cols="12">
                    <ProductDescription v-model="description" />
                  </v-col>
                </v-row>
                <!--Image-->
                <ProductImagesUpload
                  :images="images"
                  :productName="path"
                  @saveImgSrc="saveNewImage($event)"
                  @deleteImage="deleteImage($event)"
                  @saveImgAlt="saveImgAlt($event)"
                />
                <!--SEO Fields-->
                <SEOPreview
                  :item="{
                    name: name,
                    path: path,
                    description: description,
                    seoDescription: seoDescription,
                    seoTitle: seoTitle,
                    category: category,
                  }"
                  @saveSeoTags="saveSeoTags($event)"
                />
              </v-container>
            </v-form>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <!--Cancel-->
              <v-btn text @click="$router.go(-1)"
                ><v-icon small left>mdi-close</v-icon> Cancel</v-btn
              >
              <!--Remove From Website-->
              <v-btn
                text
                color="error"
                :loading="loadDelete"
                @click="removeProductFromWebsite()"
              >
                <v-icon small left>mdi-cancel</v-icon>
                Remove From Website
              </v-btn>
              <!--Save-->
              <v-btn
                class="float-right"
                color="primary"
                :loading="loading"
                @click="updateProduct()"
                >Save Product</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import store from "../store";
import { mapActions } from "vuex";
import { showSnackbar } from "../../../globalActions";
export default {
  name: "EcommerceProduct",
  components: {
    ProductDescription: () =>
      import("../components/Forms/ProductDescription.vue"),
    ProductImagesUpload: () =>
      import("../components/Forms/ProductImagesUpload.vue"),
    SEOPreview: () => import("../components/Forms/SEOPreview.vue"),
  },
  data() {
    return {
      id: null,
      name: null,
      price: null,
      path: null,
      category: null,
      images: null,
      description: null,
      rating: null,
      seoTitle: null,
      seoDescription: null,
      //
      valid: false,
      loadDelete: false,
      loading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    db.collection("ecommerce_products")
      .where("id", "==", to.params.id)
      .onSnapshot((snapshot) => {
        snapshot.docs.map((doc) => {
          next((vm) => {
            //Sets template values to those stored in the database
            Object.keys(doc.data()).forEach((k) => (vm[k] = doc.data()[k]));
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  computed: {
    docRef() {
      return db.doc(`ecommerce_products/${this.id}`);
    },
  },
  created() {
    this.setProducts();
  },
  store,
  methods: {
    ...mapActions(["setProducts"]),
    fetchData() {
      db.collection("ecommerce_products")
        .where("id", "==", this.$route.params.id)
        .onSnapshot((snapshot) => {
          snapshot.docs.map((doc) => {
            Object.keys(doc.data()).forEach((k) => (this[k] = doc.data()[k]));
          });
        });
    },
    //
    async updateProduct() {
      this.loading = true;
      try {
        await this.docRef.update({
          id: this.id,
          name: this.name,
          price: this.price,
          path: this.path,
          category: this.category,
          images: this.images,
          description: this.description,
          rating: this.rating,
        });
        showSnackbar("Product successfully updated");
        this.$router.push({ path: "/ecommerce/products" });
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    //  Updates the products images array
    async saveNewImage(image) {
      this.images.push(image);
      try {
        await this.docRef.update({ images: this.images });
        showSnackbar("Image saved to product");
      } catch (error) {
        console.log(error);
      }
    },
    // Remove image from array and database
    async deleteImage(image) {
      if (!confirm("Do you want to delete this image?")) return;
      // 1) Remove original from storage bucket
      const storageRef = firebase.storage().refFromURL(image.url);
      // 2) Remove resized from storage bucket
      const resizedRef = firebase
        .storage()
        .ref(`ecommerce-product-img/${image.resized}`);
      // 2) Remove from images array
      this.images = this.images.filter((el) => el !== image);
      // 3) Update database
      try {
        await this.docRef.update({ images: this.images });
        await storageRef.delete();
        await resizedRef.delete();
        showSnackbar("Image deleted");
      } catch (error) {
        console.log(error);
      }
    },
    //
    async saveImgAlt(event) {
      const { value: altText, index: index } = event;
      // Return early if alt text hasn't changed
      const oldALT = this.images[index].alt;
      if (oldALT === altText) return;
      // Destructure to make variables easier to read
      // Find image in array and update ALT text
      // Update images array in database
      this.images[index].alt = altText;
      try {
        await this.docRef.update({ images: this.images });
        showSnackbar("Image ALT text updated!");
      } catch (error) {
        console.log(error);
      }
    },
    //
    async saveSeoTags(event) {
      // Destructure to make variables easier to read
      const {
        seoTitle: seoTitle,
        seoDescription: seoDescription,
        newUrl: newUrl,
      } = event;
      this.seoTitle = seoTitle;
      this.seoDescription = seoDescription;
      this.path = newUrl;
      // Update database fields
      try {
        await this.docRef.update({
          seoTitle: this.seoTitle,
          seoDescription: this.seoDescription,
          path: this.path,
        });
      } catch (error) {
        console.log(error);
      } finally {
        showSnackbar("SEO tags updated!");
      }
    },
    //
    async removeProductFromWebsite() {
      if (!confirm("Are you sure you want to remove this product?")) return;
      this.loadDelete = true;
      const batch = db.batch();
      const erpRef = db.doc(`products/${this.id}`);
      // Remove ecommerce status from ERP product
      batch.update(erpRef, {
        ecommerce: firebase.firestore.FieldValue.delete(),
      });
      batch.delete(this.docRef);
      try {
        await batch.commit();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadDelete = false;
        showSnackbar("Product removed from website");
        this.$router.push({ path: "/ecommerce/products" });
      }
    },
  },
};
</script>