var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"10","offset-lg":"1"}},[_c('v-card-title',[_c('v-btn',{attrs:{"light":"","color":"#ffffff","elevation":"0","to":"/ecommerce/products"}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-chevron-left")])],1),_c('h3',[_vm._v(_vm._s(_vm.name))])],1),_c('v-card',{staticClass:"pa-3"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updateProduct($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Description")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ProductDescription',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('ProductImagesUpload',{attrs:{"images":_vm.images,"productName":_vm.path},on:{"saveImgSrc":function($event){return _vm.saveNewImage($event)},"deleteImage":function($event){return _vm.deleteImage($event)},"saveImgAlt":function($event){return _vm.saveImgAlt($event)}}}),_c('SEOPreview',{attrs:{"item":{
                  name: _vm.name,
                  path: _vm.path,
                  description: _vm.description,
                  seoDescription: _vm.seoDescription,
                  seoTitle: _vm.seoTitle,
                  category: _vm.category,
                }},on:{"saveSeoTags":function($event){return _vm.saveSeoTags($event)}}})],1)],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel")],1),_c('v-btn',{attrs:{"text":"","color":"error","loading":_vm.loadDelete},on:{"click":function($event){return _vm.removeProductFromWebsite()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Remove From Website ")],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.updateProduct()}}},[_vm._v("Save Product")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }