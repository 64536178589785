<template>
  <div>
    <v-container fluid>
      <!--Header-->
      <v-row dense>
        <v-col lg="10" offset-lg="1">
          <v-card-title>
            <!--Back button-->
            <v-btn light color="#ffffff" elevation="0" @click="$router.go(-1)">
              <v-icon left large>mdi-chevron-left</v-icon>
            </v-btn>
            <!--Save-->
            <h3>{{ name }}</h3>
          </v-card-title>
          <!--Form-->
          <v-card class="pa-3">
            <v-form @submit.prevent="updateCategory" ref="form" v-model="valid">
              <v-container>
                <!--Description-->
                <v-row>
                  <v-col cols="12">
                    <h4>Description</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="description"
                      outlined
                      class="mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!--Image-->
                <v-row>
                  <v-col cols="12"><h4>Featured Image</h4></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <CategoryImageUpload
                      :input="src"
                      @passChange="src = $event"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <!--Cancel-->
              <v-btn text @click="$router.go(-1)"
                ><v-icon small left>mdi-close</v-icon> Cancel</v-btn
              >
              <!--Remove From Website-->
              <v-btn
                text
                color="error"
                :loading="loadDelete"
                @click="removeFromWebsite()"
              >
                <v-icon small left>mdi-cancel</v-icon>
                Remove From Website
              </v-btn>
              <!--Save-->
              <v-btn
                class="float-right"
                color="primary"
                :loading="loading"
                @click="updateCategory()"
                >Save Category</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { showSnackbar } from "../../../globalActions";
export default {
  name: "EcommerceCategory",
  components: {
    CategoryImageUpload: () =>
      import("../components/Forms/CategoryImageUpload.vue"),
  },
  data() {
    return {
      id: null,
      name: null,
      description: null,
      src: null,
      path: null,
      //
      valid: false,
      loading: false,
      loadDelete: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    db.collection("ecommerce_categories")
      .where("id", "==", to.params.id)
      .onSnapshot((snapshot) => {
        snapshot.docs.map((doc) => {
          next((vm) => {
            //Sets template values to those stored in the database
            Object.keys(doc.data()).forEach((k) => (vm[k] = doc.data()[k]));
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      db.collection("ecommerce_categories")
        .where("id", "==", this.$route.params.id)
        .onSnapshot((snapshot) => {
          snapshot.docs.map((doc) => {
            Object.keys(doc.data()).forEach((k) => (this[k] = doc.data()[k]));
          });
        });
    },
    //
    async updateCategory() {
      this.loading = true;
      const docRef = db.doc(`ecommerce_categories/${this.id}`);
      try {
        await docRef.update({
          id: this.id,
          name: this.name,
          description: this.description,
          src: this.src,
          path: this.path,
        });
        showSnackbar("Category updated successfully");
        this.loading = false;
        this.$router.push({ path: "/ecommerce/products" });
      } catch (error) {
        console.log(error);
      }
    },
    //
    async removeFromWebsite() {
      if (!confirm("Are you sure you want to remove this category?")) return;
      this.loadDelete = true;
      const batch = db.batch();
      const docRef = db.collection("categories").doc(this.id);
      //  1) Remove 'ecommerce' status from product category
      batch.update(docRef, {
        ecommerce: firebase.firestore.FieldValue.delete(),
      });
      //  2) Delete document from "ecommerce" collection
      const ecomRef = db.collection("ecommerce_categories").doc(this.id);
      batch.delete(ecomRef);
      try {
        await batch.commit();
        showSnackbar("Category removed from website");
        this.loadDelete = false;
        this.$router.push({ path: "/ecommerce/products" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>