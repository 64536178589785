import Vue from 'vue';
import Vuex from 'vuex';
import db from "../../../components/firebaseInit"
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        categories: [],
        products: [],
    },

    getters: {
        filteredCategories: (state) => {
            return state.categories.map(el => el.name)
        },
        urlList: (state) => {
            return state.products.map(el => el.path)
        }
    },

    actions: {
        setCategories: async (context) => {
            const docRef = db.collection('ecommerce_categories')
            try {
                const snapshot = await docRef.get()
                const categories = snapshot.docs
                    .map(doc => ({ ...doc.data() }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1));
                context.commit("setCategories", categories)
            } catch (error) {
                console.log(error)
            }
        },
        setProducts: async (context) => {
            const docRef = db.collection("ecommerce_products")
            try {
                const snapshot = await docRef.get()
                const products = snapshot.docs.map(doc => ({ ...doc.data() }))
                context.commit("setProducts", products)
            } catch (error) {
                console.log(error)
            }
        },
    },

    mutations: {
        setCategories: (state, payload) => (state.categories = payload),
        setProducts: (state, payload) => (state.products = payload),
    },
})